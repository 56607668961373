import { Breadcrumbs, EmptyState, Loader } from 'components'
import { AttendanceModal } from 'domains/occurrence/components'
import { SearchOccurrenceEmptyState } from 'domains/occurrence/components/SearchOccurrenceEmptyState/SearchOccurrenceEmptyState'
import { useHandleUpdateUserAndCheckOccurrenceAttendant } from 'domains/occurrence/hooks'
import { useGetOccurrenceForAttend } from 'domains/occurrence/hooks/services/occurrence/useGetOccurrenceForAttend'
import { usePostAndHandleAttendance } from 'domains/occurrence/hooks/services/occurrence/usePostAndHandleAttendance'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useToast, useToggle, useUserInfo } from 'shared/hooks'

import styles from './styles.module.scss'
import { buildPath, paths } from 'routes'

const SearchOccurrence = () => {
  const [startAttendance, setStartAttendance] = useState<boolean>(false)
  const [isInvalidAttendantModalVisible, setisInvalidAttendantModalVisible] =
    useState(true)

  const navigate = useNavigate()
  const { isQualifiedAttendant } =
    useHandleUpdateUserAndCheckOccurrenceAttendant()

  const attendanceModal = useToggle()

  const { mutate: mutateCreateAttendance } = usePostAndHandleAttendance()

  const { data: occurrenceForAttend } =
    useGetOccurrenceForAttend(startAttendance)

  const handleGoToOccurrenceAttendance = useCallback(
    (occurrenceId: string) => {
      navigate(
        buildPath({
          path: paths.occurrence.attendance,
          params: { occurrenceId },
        }),
      )
    },
    [navigate],
  )

  const { addToast } = useToast()
  const { userInfo } = useUserInfo()

  useEffect(() => {
    if (occurrenceForAttend) {
      if (occurrenceForAttend.stateName === 'AVAILABLE') {
        mutateCreateAttendance(
          {
            occurrenceId: occurrenceForAttend.id,
            userId: userInfo.id,
          },
          {
            onSuccess: (data) => {
              navigate(
                buildPath({
                  path: paths.occurrence.attendance,
                  params: { occurrenceId: data.id },
                }),
                {
                  state: {
                    shouldRedirectToOccurrenceManagement: false,
                  },
                },
              )
            },
            onError: () => {
              addToast({
                message: 'Erro ao criar atendimento.',
                error: true,
                type: 'alert',
              })
            },
          },
        )
      } else if (occurrenceForAttend.stateName !== 'FINISHED') {
        handleGoToOccurrenceAttendance(occurrenceForAttend.id)
      }
    }
  }, [mutateCreateAttendance, occurrenceForAttend, history, addToast]) // eslint-disable-line

  useEffect(() => {
    attendanceModal.show()
  }, [])

  if (!isQualifiedAttendant)
    return (
      <SearchOccurrenceEmptyState
        isVisible={isInvalidAttendantModalVisible}
        onClose={() => setisInvalidAttendantModalVisible(false)}
      />
    )

  return (
    <div className={styles.container}>
      <Loader isVisible={startAttendance}>
        <div className={styles.loaderInfoWrapper}>
          <p className={styles.loaderTitle}>Aguardando nova ocorrência...</p>
          <p className={styles.loaderInfo}>
            Isto pode demorar alguns segundos.
          </p>
        </div>
      </Loader>
      <AttendanceModal
        isVisible={attendanceModal.isVisible}
        onClose={() => {
          attendanceModal.hide()
          setStartAttendance(false)
        }}
        onContinue={() => {
          attendanceModal.hide()
          setStartAttendance(true)
        }}
      />
      <div>
        <Breadcrumbs
          items={[
            {
              title: 'Home',
              href: '',
            },
            { title: 'Ocorrências', href: '' },
            { title: 'Atendimento', href: '' },
          ]}
        />
        <div className={styles.headerSeparator} />
      </div>
      {!startAttendance && (
        <div className={styles.emptyStateWrapper}>
          <EmptyState type="EmptyOccurrence" />
        </div>
      )}
    </div>
  )
}

export default SearchOccurrence
